<template>
  <v-container>
    <div id="published-months" v-if="user && publishedMonths">
      <v-card class="elevation-0">
        <v-data-table
          class="table-cursor"
          :headers="headers"
          :items="submissions"
          :search="search"
          hide-default-footer
          @click:row="rowClick"
        >
        </v-data-table>
      </v-card>

      <v-dialog v-model="itemDialog" scrollable max-width="700px">
        <v-card>
          <v-card-title>{{ form.title }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-form v-model="formValid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="startmenu"
                      v-model="startmonthMenu"
                      :close-on-content-click="false"
                      :return-value.sync="form.startmonth"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.startmonth"
                          label="Startmåned"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.startmonth"
                        type="month"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="startmonthMenu = false"
                        >
                          Annuller
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.startmenu.save(form.startmonth)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="endmenu"
                      v-model="endmonthMenu"
                      :close-on-content-click="false"
                      :return-value.sync="form.endmonth"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.endmonth"
                          label="Slutmåned"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.endmonth"
                        type="month"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="endmonthMenu = false"
                        >
                          Annuller
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.endmenu.save(form.endmonth)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-4">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div></div>
              <div>
                <v-btn class="primary--text" text @click="itemDialog = false">
                  Annuller
                </v-btn>
                <v-btn class="primary ml-6" @click="dialogConfirmSave">
                  Gem
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DateTime from "../utils/DateTime";
import { required, number } from "../utils/FormRules";

export default {
  name: "PublishedMonths",
  data() {
    return {
      monthpicker: null,
      startmonthMenu: false,
      endmonthMenu: false,
      itemDialog: false,
      dialogEditId: null,
      search: "",
      headers: [
        {
          text: "Titel",
          align: "start",
          value: "title",
        },
        { text: "Startår", value: "startyear" },
        { text: "Startmåned", value: "startmonthName" },
        { text: "Slutår", value: "endyear" },
        { text: "Slutmåned", value: "endmonthName" },
      ],
      defaultForm: {
        title: "",
        startmonth: null,
        endmonth: null,
      },
      form: {},
      formValid: false,
      formRules: {
        requiredRule: required,
        numberRule: number,
      },
    };
  },
  mounted() {
    this.loadPublishedMonths();
  },
  methods: {
    ...mapActions([
      "loadPublishedMonths",
      "createPublishedMonth",
      "savePublishedMonth",
    ]),
    dialogConfirmSave() {
      const startYearMonth = DateTime.yearmonthstringToYearMonth(
        this.form.startmonth
      );
      const endYearMonth = DateTime.yearmonthstringToYearMonth(
        this.form.endmonth
      );
      this.savePublishedMonth({
        publishedMonthId: this.dialogEditId,
        publishedMonth: {
          startyear: startYearMonth[0],
          startmonth: startYearMonth[1],
          endyear: endYearMonth[0],
          endmonth: endYearMonth[1],
        },
      });
      this.itemDialog = false;
    },
    rowClick(row) {
      const startmonth = DateTime.yearMonthToYearmonthstring(
        row.startyear,
        row.startmonth
      );
      const endmonth = DateTime.yearMonthToYearmonthstring(
        row.endyear,
        row.endmonth
      );
      this.form.startmonth = startmonth;
      this.form.endmonth = endmonth;
      this.form.title = row.title;

      this.dialogEditId = row._id;
      this.itemDialog = true;
    },
  },
  computed: {
    ...mapState(["loaded", "user", "token", "publishedMonths"]),
    submissions() {
      if (this.publishedMonths) {
        return this.publishedMonths.map((sub) => {
          return {
            _id: sub._id,
            title: sub.title,
            startyear: sub.startyear,
            startmonth: sub.startmonth,
            startmonthName: DateTime.monthnameFromIndex(sub.startmonth),
            endyear: sub.endyear,
            endmonth: sub.endmonth,
            endmonthName: DateTime.monthnameFromIndex(sub.endmonth),
          };
        });
      }

      return [];
    },
  },
};
</script>
<style>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
